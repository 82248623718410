<template>
  <div>
    <nav-bar />

    <div class="main">
      <page-header
        title="Tracking Permohonan"
        subtitle="Silahkan gunakan nomor permohonan dari OSS untuk mencari permohonan Anda"
      />
      <section class="review-section ptb-100">
        <div class="container">
          <div
            class="mb-5"
            style="width: 100%"
          >
            <!-- <label
              for="nomor_permohonan"
              class="form-label"
            >
              Nomor Permohonan
            </label> -->
            <div class="d-flex">
              <input
                id="nomor_permohonan"
                v-model="nomor_permohonan"
                type="text"
                class="form-control"
                placeholder="Contoh: I-123456"
              />
              <button
                style="width: 200px"
                type="button"
                class="btn btn-primary ml-2"
                @click="getTracking"
              >
                Cari Permohonan
              </button>
            </div>
          </div>
          <div v-if="is_exist === true">
            <div
              v-for="(data, index) in dataTracking"
              :key="index"
            >
              <div class="position-relative">
                <div class="tracking-bullet"></div>
                <div
                  :class="[
                    { 'tracking-item': index !== dataTracking.length - 1 },
                    {
                      'last-tracking-item': index === dataTracking.length - 1
                    }
                  ]"
                >
                  <p class="tracking-title">
                    {{ data.status_permohonan }}
                  </p>
                  <p>
                    {{ formatDate(data.createdAt, 'HH:mm DD MMMM YYYY') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="is_exist === false">Nomor permohonan tidak ditemukan</div>
        </div>
      </section>
    </div>
    <site-footer :show-subscribe="true" />
    <copyright />
  </div>
</template>

<style>
.tracking-bullet {
  position: absolute;
  background-color: #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.tracking-item {
  border-left: 6px solid #007bff;
  margin-left: 12px;
  padding-left: 20px;
  height: 100px;
}

.last-tracking-item {
  margin-left: 12px;
  padding-left: 26px;
}

.tracking-title {
  margin: 0px;
  font-weight: bold;
  font-size: large;
}
</style>

<script>
import NavBar from '../views/commons/NavBar'
import PageHeader from '../views/commons/PageHeader'
import SiteFooter from '../views/commons/SiteFooter'
import Copyright from '../views/commons/Copyright'
import config from '../config'

import dayjs from 'dayjs'
import 'dayjs/locale/id'

export default {
  name: 'GalleryPage',
  components: {
    SiteFooter,
    PageHeader,
    NavBar,
    Copyright
  },
  data() {
    return {
      baseApiUrl: config.baseApiUrl,
      nomor_permohonan: '',
      is_exist: null,
      dataTracking: []
    }
  },
  methods: {
    dayjs,
    formatDate(date, format) {
      return dayjs(date).locale('id').format(format)
    },

    async getTracking() {
      const res = await this.$axios.get(
        `https://be.esea.mysurvey.id/api/permohonan?filter=nomor_permohonan,=,${this.nomor_permohonan}`
      )
      if (res.data.data.length === 0) {
        this.is_exist = false
        return
      }
      this.is_exist = true
      const id_permohonan = res.data.data[0].id

      this.$axios
        .get(
          `https://be.esea.mysurvey.id/api/status_permohonan?filter=id_permohonan,=,${id_permohonan}`
        )
        .then((res) => {
          const data = res.data.data.reverse()
          const tracking = []
          data.map((d) => {
            if (
              !tracking.find(
                (d2) => d.status_permohonan === d2.status_permohonan
              )
            ) {
              tracking.push(d)
            }
          })
          this.dataTracking = tracking.reverse()
        })
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact-us-section ptb-100",class:{ 'gray-light-bg': _vm.isGray },attrs:{"id":"report"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col-12 pb-3 message-box",class:{
          'd-none': !_vm.isSuccess && !_vm.hasError,
          'd-block': _vm.isSuccess || _vm.hasError
        }},[_c('div',{staticClass:"alert",class:{
            'alert-danger': _vm.hasError,
            'alert-success': _vm.isSuccess
          }},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])]),_c('div',{staticClass:"col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0"},[(_vm.data)?_c('div',{staticClass:"contact-us-form gray-light-bg rounded p-5 text-center"},[_c('h4',[_vm._v(_vm._s(_vm.data.title))]),_c('p',[_vm._v(_vm._s(_vm.data.description))]),_c('a',{staticClass:"btn btn-brand-02 font-weight-bold",attrs:{"type":"submit","id":"btnContactUs","href":_vm.data.url}},[_vm._v(" Unduh Template ")])]):_vm._e()]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/report-illust.jpg"),"alt":"Report illustration","srcse":""}})])
}]

export { render, staticRenderFns }